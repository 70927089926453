<template>
  <main class="main ic">
    <div class="container">
      <div class="main__inner">
        <BreadcrumbsComponent title="Все инициативы" />
        <section class="section">
          <div class="ic__content">
            <FilterComponent :categories="categories" :loading="loading" @change="handleFilterChange" />
            <IcItemsList
              title="Все инициативы"
              :items="items.data"
              :paginator-info="items.paginatorInfo"
              :order-by="ordering[0].orderBy"
              :first="first"
              :page="page"
              :loadingType="loadingType"
              :loading="loading"
              @paginate="paginate"
              @changeOrdering="handleChangeOrdering"
            />
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import BreadcrumbsComponent from "components/BreadcrumbsComponent.vue";
import IcItemsList from "./components/IcItemsList.vue";
import FilterComponent from "./components/FilterComponent.vue";

const page = 1;
const first = 9;

export default {
  name: "AllCityIdeasPage",
  async asyncData({ apollo, store }) {
    await store.dispatch("GET_CITY_IDEAS", {
      apollo: apollo.clients.ic,
      variables: {
        page: page,
        ordering: [
          {
            orderColumn: "popularity",
            orderBy: "desc",
          },
        ],
        first: first,
      },
      loadingType: store.state.loading_types.DEFAULT,
    });
  },
  data() {
    return {
      loading: false,
      page: page,
      first: first,
      ordering: [
        {
          orderColumn: "popularity",
          orderBy: "desc",
        },
      ],
      loadingType: this.$store.state.loading_types.PAGINATION,
      title: null,
      category: null,
      start_date: null,
      end_date: null,
    };
  },
  computed: {
    items() {
      return this.$store.state.city_ideas || {};
    },
    categories() {
      return this.$store.state.ic_categories || [];
    },
  },
  methods: {
    async handleFilterChange(form = {}) {
      if (!this.loading) {
        this.title = form.title.value;
        this.category = form.categories.value;
        this.start_date = form.start_date.value;
        this.end_date = form.end_date.value;
        this.loading = true;
        this.page = 1;
        await this.load();
        this.loading = false;
      }
    },
    async handleChangeOrdering() {
      if (!this.loading) {
        this.loading = true;
        this.page = 1;
        this.ordering[0].orderBy = this.ordering[0].orderBy === "desc" ? "asc" : "desc";
        await this.load();
        this.loading = false;
      }
    },
    async paginate(page) {
      if (!this.loading) {
        this.page = page;
        this.loading = true;
        await this.load();
        this.loading = false;
      }
    },
    async load() {
      if (this.loadingType !== this.$store.state.loading_types.LOAD_MORE) {
        window.scrollTo(0, 0);
      }
      // let data = {};
      // Object.keys(form).forEach((key) => {
      //   if (form[key] === "categories") data[key] = [form[key].value];
      //   else if (form[key].value !== null) data[key] = form[key].value;
      // });
      await this.$store.dispatch("GET_CITY_IDEAS", {
        apollo: this.$apollo.provider.clients.ic,
        variables: {
          title: this.title,
          categories: this.category ? [this.category.id] : null,
          page: this.page,
          ordering: this.ordering,
          first: this.first,
        },
        loadingType: this.loadingType,
      });
    },
  },
  metaInfo() {
    return this.$seo(
      "common",
      "Официальный сайт администрации города Махачкалы",
      "",
      "",
      "Официальный сайт администрации города Махачкалы",
      "",
      ""
    );
  },
  components: {
    BreadcrumbsComponent,
    IcItemsList,
    FilterComponent,
  },
};
</script>

<style lang="stylus">
.sc__welcome {
  position relative
  background-image linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%, #DDE8EF 100%)
  border-bottom 1px solid var(--color_gray_divider)

  &-blocks {
    display flex
    align-items center
    justify-content space-between
    padding 105px 64px 132px
    gap 32px
    overflow-x auto
    background-image url("/public/images/sc/sc_welcome.png")
    background-repeat no-repeat
    background-size auto
    background-position: center;
    +below(880px) {
      padding-left 24px
      padding-right 24px
      gap 64px
    }
    +below(768px) {
      padding-top 60px
      padding-bottom 60px
    }

    &::-webkit-scrollbar {
      display none
    }

    img {
      width 128px
      min-width 128px
      height 128px
      object-fit contain
      +below(1500px) {
        width: 80px
        min-width 80px
        height: 80px
      }
    }
  }

  &-block {
    flex-shrink 0
    display flex
    align-items center
    gap 16px
    +below(1200px) {
      flex-direction column
      align-items flex-start
    }

    span {
      flex-shrink 0
      font-weight: 600;
      font-size: 1.5em
      line-height: 40px;
      text-transform: uppercase;
      +below(1500px) {
        font-size: 1.25em
        line-height: 32px
      }
    }
  }

  &-bottom {
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    padding 0 64px 24px
    gap 24px
    +below(880px) {
      padding 10px 24px 16px
    }
    +below(620px) {
      display grid
      grid-template-columns 1fr 1fr
      align-items flex-start
      gap 16px
      padding-top 10px
    }
    +below(480px) {
      grid-template-columns 1fr
    }

    &-text {
      font-size: 1.375em
      line-height: 28px;
      color var(--color_green_dark)
      +below(1200px) {
        font-size 1.125em
        line-height 24px
      }
      +below(768px) {
        font-size: 1em
      }
    }

    .sc-stats__item {
      display flex
      align-items center
      justify-content: flex-end;
      color var(--color_dark)
      gap 16px
      flex-shrink 0
      +below(768px) {
        gap 8px
      }
      +below(620px) {
        justify-content: flex-start;
      }
    }

    .sc-stats__number {
      font-weight: 500;
      font-size: 3em
      line-height: 44px;
      +below(920px) {
        font-size: 2.5em
        line-height: 40px;
      }
      +below(768px) {
        font-size: 2em
      }
    }

    .sc-stats__title {
      font-size: 1.125em
      line-height: 22px;
      +below(920px) {
        font-size: 1em
        line-height: 20px
      }
      +below(768px) {
        font-size: 0.875em
        line-height: 16px;
      }
    }

    .sc__report-link {
      padding 20px 32px
      box-shadow: 5px 6px 12px var(--color_black_o08)
      border-radius: 4px;
      font-weight: 500;
      line-height: 24px;
      width 360px
      height auto
      border none
      +below(990px) {
        width auto
      }
      +below(920px) {
        font-size: 1em
        line-height: 24px;
        padding 10px 17px
      }
      +below(768px) {
        font-size: 0.875em
        line-height: 20px;
        min-height 40px
      }
    }
  }
}

.sc__title {
  font-size 1.5em
  line-height 125%
}

.sc__show-more {
  margin-top: 24px;
}
</style>
