<template>
  <div class="ic-vote__modal">
    <button class="modal__close" @click="$emit('close')">
      <CloseIcon />
    </button>
    <span class="ic-vote__number">
      Идея #{{ idea.id }} от
      <time :datetime="idea.created_at | robotDate">{{ idea.created_at | formatDate }}</time> г.
    </span>
    <h2 class="ic-vote__title">Проголосовать за идею</h2>
    <form @submit.prevent="submitForm">
      <div class="ic-vote__blocks">
        <div v-if="idea.user" class="ic-vote__block">
          <span class="ic-vote__block-title">Инициатор:</span>
          <span class="ic-vote__block-content">{{ idea.user.name }}</span>
        </div>
        <div v-if="idea.text" class="ic-vote__block">
          <span class="ic-vote__block-title">Суть идеи:</span>
          <span class="ic-vote__block-content">
            {{ idea.text }}
          </span>
          <div v-if="idea.head_img" class="ic-vote__block-img">
            <div class="img__container">
              <img :src="idea.head_img | image($store.state.api)" :alt="idea.head_img | image_alt" />
            </div>
          </div>
          <div class="docs-list">
            <a
              v-for="(doc, i) in idea.join_documents"
              :key="i"
              download
              target="_blank"
              :href="$store.state.api + doc.document.url"
              class="docs-item"
            >
              <DocumentIcon />
              <div class="doc-info">
                <div v-if="doc.document.origin_name" class="doc-title">
                  {{ doc.document.origin_name }}
                </div>
                <div v-if="doc.document.type || doc.document.size" class="doc-size">
                  <span>{{ doc.document.type }}</span> ({{ doc.document.size }})
                </div>
              </div>
            </a>
          </div>
        </div>
        <div v-if="idea.address" class="ic-vote__block">
          <span class="ic-vote__block-title">Предложенное место реализации идеи:</span>
          <span class="ic-vote__block-content text--big"> {{ idea.address }} </span>
          <yandex-map :settings="settings" :coords="coords" v-if="mapReady" zoom="16">
            <ymap-marker marker-id="0" :coords="coords" :icon="icon" />
          </yandex-map>
        </div>
        <div class="ic-vote__block ic-vote__btns">
          <button
            @click="voteLike(true)"
            :class="{ active: idea.auth_vote && idea.auth_vote.like }"
            class="btn-green_dark_tr btn--small vote-like"
            type="button"
          >
            <LikeIcon /> <span>Нравится</span>
            <span class="votes-count">{{ idea.vote_like_count || 0 }}</span>
          </button>
          <button
            @click="voteLike(false)"
            :class="{ active: idea.auth_vote && idea.auth_vote.dislike }"
            class="btn-red_tr btn--small vote-dislike"
            type="button"
          >
            <LikeIcon /> <span>Не нравится</span>
            <span class="votes-count">{{ idea.vote_dislike_count || 0 }}</span>
          </button>
        </div>
        <div v-if="form.dislike && !voteAccepted && !voteError" class="form__item-full ic-vote-form__item">
          <label for="comment">Напишите что вам не понравилось</label>
          <textarea v-model="form.comment" id="comment" rows="10" placeholder="Комментарий"></textarea>
          <button class="btn-blue btn--small" type="submit">Отправить</button>
        </div>
        <div v-if="voteAccepted || voteError" class="ic-vote__block">
          <span :class="{ error: voteError }" class="ic-vote__accepted">
            <template v-if="voteAccepted && !voteError">Ваш голос принят</template>
            <template v-else-if="voteError && !voteAccepted">Ошибка, вы уже голосовали за эту идею</template>
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import CloseIcon from "components/svg/CloseIcon.vue";
import LikeIcon from "@/components/svg/LikeIcon.vue";
import DocumentIcon from "@/components/svg/DocumentIcon.vue";
import IC_VOTE from "@/graphql/ic/VoteCreate.graphql";
import LoginModal from "components/modals/LoginModal.vue";
import { loadYmap, yandexMap, ymapMarker } from "vue-yandex-maps";

export default {
  name: "IdeaVoteModal",
  props: {
    options: {
      type: Object,
    },
  },
  data() {
    return {
      mapReady: false,
      voteAccepted: false,
      voteError: false,
      coords: [0, 0],
      settings: {
        apiKey: "4f77a55a-eec1-4f77-961f-42cd4e50b13c",
        lang: "ru_RU",
        coordorder: "latlong",
        version: "2.1",
      },
      icon: {
        layout: "default#imageWithContent",
        imageHref: "/public/images/map-red-location.png",
        imageSize: [32, 36],
        imageOffset: [-18, -18],
      },
      form: {
        like: null,
        dislike: null,
        comment: null,
      },
    };
  },
  mounted() {
    loadYmap({
      ...this.settings,
    }).then(() => {
      window.ymaps.ready(() => {
        window.ymaps.geocode(this.idea.address).then((res) => {
          this.coords = res.geoObjects.get(0).geometry.getCoordinates();
          this.mapReady = true;
        });
      });
    });
  },
  computed: {
    idea() {
      return this.$store.state.one_idea;
    },
  },
  methods: {
    voteLike(like) {
      if (localStorage.getItem("apollo-token")) {
        if (like) {
          this.form.like = !this.form.like;
          this.form.dislike = false;
          this.submitForm();
        } else {
          this.form.like = false;
          this.form.dislike = !this.form.dislike;
          if (!this.form.dislike) {
            this.submitForm();
          }
        }
        this.clearResponse();
      } else {
        this.showLoginModal();
      }
    },
    showLoginModal() {
      this.$store.state._modals = [
        {
          component: LoginModal,
        },
      ];
    },
    clearResponse() {
      this.voteAccepted = false;
      this.voteError = false;
    },
    async submitForm() {
      if (!this.loading) {
        this.loading = true;
        this.clearResponse();
        await this.$apollo.provider.clients.ic
          .mutate({
            mutation: IC_VOTE,
            variables: {
              idea_townspeople_id: this.idea.id,
              like: this.form.like,
              dislike: this.form.dislike,
              comment: this.form.comment,
            },
            context: {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("apollo-token"),
              },
            },
          })
          .then(() => {
            this.loading = false;
            this.voteAccepted = true;
            this.$store.dispatch("GET_ONE_IDEA", {
              apollo: this.$apollo.provider.clients.ic,
              variables: {
                id: this.idea.id,
              },
              token: localStorage.getItem("apollo-token")
            });
          })
          .catch((err) => {
            console.error(err);
            this.loading = false;
            this.voteError = true;
          });
      }
    },
  },
  components: {
    CloseIcon,
    LikeIcon,
    DocumentIcon,
    yandexMap,
    ymapMarker,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/ratio.styl"
@import "~@/styles/parts/docs-list.styl"

.ic-vote {
  &__modal {
    width 95vw
    max-width 720px
    overflow hidden
    padding 32px 64px 64px
    color var(--color_dark)
    background: var(--color_white);
    border-radius: var(--radius);
    +below(1200px) {
      max-width 960px
    }
    +below(768px) {
      max-width 512px
      padding 24px
    }
    +below(540px) {
      width auto
    }
    +below(420px) {
      padding 16px
    }
  }

  &__number {
    font-size: 0.875em
    line-height: 20px;
    color var(--color_gray_dark)
  }

  &__title {
    font-weight: bold;
    font-size: 1.875em
    line-height: 36px;
    +below(768px) {
      font-weight: 500;
      font-size: 1.125em
      line-height: 24px;
      margin-bottom: 24px;
    }
  }

  &__blocks {
    display flex
    flex-direction column
    gap 24px
    +below(768px) {
      gap 16px
    }
  }

  &__block {
    display flex
    flex-direction column
    gap 6px
    +below(768px) {
      font-size 0.875em
      line-height 20px
    }

    &-title {
      color var(--main_border_color)
    }

    &-content {
      white-space pre-line

      &.text--big {
        font-size 1.125em
        line-height 24px
        +below(768px) {
          font-size 0.875em
          line-height 20px
        }
      }
    }

    &-img {
      ratio(592, 340)
    }

    &.ic-vote__btns {
      display grid
      grid-template-columns 1fr 1fr
      grid-gap 16px
      +below(540px) {
        grid-template-columns 1fr
      }

      button {
        display flex
        text-align left
        justify-content flex-start
        gap 10px
        transition 0.1s

        &.active
        &:hover
        &.vote-dislike.active
        &.vote-dislike:hover {
          svg {
            svg(var(--color_white))
          }
        }

        i {
          flex-shrink 0
          width 24px
          height 24px
          margin 0
        }

        &.vote-dislike {
          svg {
            svg(var(--color_red))
            transform rotate(180deg)
          }
        }
      }

      .votes-count {
        margin-left auto
      }
    }

    .ymap-container {
      width 100%
      height 340px
      +below(420px) {
        height 280px
      }
      +below(380px) {
        height 220px
      }
    }
  }

  &-form__item {
    button {
      width 166px
      margin-top 24px
      +below(768px) {
        margin-top 16px
      }
    }
  }

  &__accepted {
    &.error {
      color var(--color_red)
    }
  }
}
</style>
