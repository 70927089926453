<template>
  <div class="ic-items">
    <div class="ic-items__top">
      <h4 class="ic-items__title">{{ title }}</h4>
      <div :class="{ 'ordering-asc': orderBy === 'asc' }" class="ic-ordering">
        <span class="ic-ordering__title">Сортировать по: </span>
        <span @click="changeOrdering" class="ic-ordering__item">
          <span>Популярности</span>
          <ArrowDownIcon />
        </span>
      </div>
    </div>
    <LoadingIndicator v-if="!items" />
    <ul v-else-if="items && items.length" class="ic-items__list">
      <li class="ic-items__item" v-for="(item, i) in items" :key="i">
        <IcItemComponent :item="item" />
      </li>
    </ul>
    <span v-else>Ничего нет</span>
    <PaginationComponent
      v-if="showPagination"
      :loadingType="loadingType"
      :page="page"
      :total="Math.ceil(paginatorInfo.total / first)"
      @change="paginate"
    />
    <a v-else-if="loading" href="#" class="show__more__link">
      <LoadingIndicator title="Загрузка" />
    </a>
  </div>
</template>

<script>
import PaginationComponent from "components/Pagination.vue";
import IcItemComponent from "./IcItemComponent.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "IcItemsList",
  props: {
    title: String,
    items: Array,
    paginatorInfo: Object,
    loadingType: Number,
    first: Number,
    page: Number,
    loading: Boolean,
    orderBy: {
      type: String,
      default() {
        return "desc";
      },
    },
  },
  computed: {
    showPagination() {
      return !this.loading && this.paginatorInfo && this.paginatorInfo.total > this.first;
    },
  },
  methods: {
    paginate(page) {
      this.$emit("paginate", page);
    },
    changeOrdering() {
      this.$emit("changeOrdering");
    },
  },
  components: {
    IcItemComponent,
    PaginationComponent,
    LoadingIndicator,
    ArrowDownIcon,
  },
};
</script>

<style lang="stylus">
.ic-items {
  display grid
  grid-gap 32px
  width 100%
  +below(1200px) {
    grid-gap 24px
  }

  &__top {
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    gap 16px

    .ic-items__title {
      margin 0
    }
  }

  .ic-ordering {
    display flex
    align-items center
    gap 16px

    &__title {
      color var(--color_gray_dark)
    }

    &__item {
      display flex
      align-items center
      justify-content space-between
      gap 32px
      min-width 213px
      padding 6px 6px 6px 12px
      border 1px solid var(--color_gray_divider)
      border-radius 4px
      cursor pointer
    }

    &.ordering-asc .ic-ordering__item svg {
      transform rotate(180deg)
    }
  }

  &__list {
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 32px
    +below(1200px) {
      grid-gap 24px
    }
    +below(990px) {
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      grid-gap 16px
    }
    +below(620px) {
      grid-template-columns 1fr
    }
  }

  .pagination {
    margin 0
  }
}
</style>
