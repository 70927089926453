<template>
  <div class="ic-filter filter__form-change-wrap">
    <form
      @submit.prevent="emitSubmit"
      ref="filterForm"
      class="filter__form filter__form-change"
      autocomplete="off"
    >
      <div class="search filter">
        <div class="search__input">
          <label for="search" class="search__label">Название идеи</label>
          <div class="search__input__wrap">
            <input v-model="form.title.value" id="search" type="text" maxlength="255" />
            <button v-if="form.title.value" type="button" @click="resetSearchInput">
              <CloseIcon />
            </button>
          </div>
        </div>
        <div class="filter__item">
          <label>Темы</label>
          <Multiselect
            track-by="id"
            label="title"
            v-model="form.categories.value"
            :options="categories"
            placeholder="Все"
            selectLabel="Выбрать ↵"
            deselectLabel="Удалить ↵"
            selectedLabel="Выбрано"
            :searchable="true"
            :allow-empty="true"
          >
            <span slot="noOptions">Список пуст</span>
            <span slot="noResult">Ничего не найдено</span>
          </Multiselect>
        </div>
        <div class="filter__item filter__date">
          <label>Диапазон дат</label>
          <div class="filter__date__inputs">
            <div class="filter__date__input">
              <flat-pickr v-model="form.start_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
            <div class="filter__date__input">
              <flat-pickr v-model="form.end_date.value" :config="flatPickrConfig" placeholder="дд.мм.гггг">
              </flat-pickr>
              <DatePickIcon />
            </div>
          </div>
        </div>
        <div class="search__btn search__btn--with-clear">
          <a @click.prevent="emitReset" href="#" class="filter__reset"> Сбросить фильтр </a>
          <button type="submit" class="btn-blue_dark" :disabled="loading">
            <LoadingIndicator v-if="loading" title="Загрузка" />
            <template v-else>Найти</template>
          </button>
        </div>
      </div>
    </form>

    <a
      @click.prevent="changeFilterForm"
      class="filter__change-btn"
      :class="{ hide__btn: isFilterVisible }"
      href="#"
    >
      <span v-if="isFilterVisible">Скрыть фильтр</span>
      <span v-else>Показать фильтр</span>
      <ArrowDownIcon />
    </a>
  </div>
</template>

<script>
import FlatPickr from "vue-flatpickr-component";
import { Russian } from "flatpickr/dist/l10n/ru.js";
import Multiselect from "vue-multiselect";
import DatePickIcon from "@/components/svg/DatePickIcon.vue";
import LoadingIndicator from "@/components/LoadingIndicator.vue";
import CloseIcon from "@/components/svg/CloseIcon.vue";
import ArrowDownIcon from "@/components/svg/ArrowDown.vue";

export default {
  name: "IcFilterComponent",
  props: {
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    loading: Boolean,
  },
  data() {
    return {
      isFilterVisible: false,
      flatPickrConfig: {
        locale: Russian,
        enableTime: true,
        defaultHour: 0,
        defaultMinute: 0,
        dateFormat: "d.m.Y H:i",
        disableMobile: true,
      },
      form: {
        title: {
          value: null,
          message: null,
        },
        start_date: {
          value: null,
          message: null,
        },
        end_date: {
          value: null,
          message: null,
        },
        categories: {
          value: null,
          message: null,
        },
      },
    };
  },
  methods: {
    changeFilterForm() {
      let filter = this.$refs.filterForm;
      filter.style.display = this.isFilterVisible ? "none" : "flex";
      this.isFilterVisible = !this.isFilterVisible;
    },
    /**
     * Фильтр
     * @returns {Promise<void>}
     */
    emitSubmit() {
      this.$emit("change", this.form);
    },
    /**
     * Сброс фильтра
     */
    emitReset() {
      Object.keys(this.form).forEach((key) => {
        this.form[key].value = null;
      });
      this.emitSubmit();
    },
    /**
     * Очищение строки поиска
     */
    resetSearchInput() {
      this.form.title.value = null;
    },
  },
  components: {
    FlatPickr,
    Multiselect,
    DatePickIcon,
    LoadingIndicator,
    CloseIcon,
    ArrowDownIcon,
  },
};
</script>

<style lang="stylus">
@import '~flatpickr/dist/flatpickr.min.css'
@import '~vue-multiselect/dist/vue-multiselect.min.css'

.ic-filter {
  margin-bottom: 32px;
  +below(1200px) {
    margin-bottom: 24px;
  }
}
</style>
