<template>
  <a @click.prevent="showVoteIdeaModal()" class="ic-item">
    <div class="ic-item__img">
      <div class="img__container">
        <img :src="item.head_img | image($store.state.api)" :alt="item.head_img | image_alt" />
      </div>
      <span v-if="item.show_author" class="ic-item__author">Предложил: {{ item.user.name }}</span>
    </div>
    <div class="ic-item__info">
      <span v-if="item.title" class="ic-item__title">
        {{ item.title }}
      </span>
      <div class="ic-item__bottom">
        <span class="ic-item__date">
          <time :datetime="item.created_at | robotDate">
            {{ item.created_at | formatDate }}
          </time>
        </span>
        <span class="ic-item__votes">
          <span class="ic-item__likes">
            <LikeIcon />
            <span>{{ item.vote_like_count || 0 }}</span>
          </span>
          <span class="ic-item__dislikes">
            <LikeIcon />
            <span>{{ item.vote_dislike_count || 0 }}</span>
          </span>
        </span>
      </div>
    </div>
  </a>
</template>

<script>
import LikeIcon from "@/components/svg/LikeIcon.vue";
import IdeaVoteModal from "./modals/IdeaVoteModal.vue";

export default {
  name: "IcItemComponent",
  props: {
    item: Object,
  },
  methods: {
    async showVoteIdeaModal() {
      await this.$store
        .dispatch("GET_ONE_IDEA", {
          apollo: this.$apollo.provider.clients.ic,
          variables: {
            id: this.item.id,
          },
          token: localStorage.getItem("apollo-token"),
        })
        .then(() => {
          this.$store.state._modals.push({
            component: IdeaVoteModal,
          });
        });
    },
  },
  components: {
    LikeIcon,
  },
};
</script>

<style lang="stylus">
@import "~@/styles/mixins/svg.styl"
@import "~@/styles/mixins/textcut.styl"
@import "~@/styles/mixins/ratio.styl"

.ic-item {
  display flex
  flex-direction column
  gap 12px
  padding 12px 16px
  border 1px solid var(--color_gray_divider)

  &:hover {
    border 1px solid var(--color_gray_divider)
  }

  &__img {
    ratio(460, 370)
  }

  &__author {
    position absolute
    bottom 0
    left 0
    right 0
    z-index 10
    display flex
    align-items flex-end
    padding 8px 12px
    height 93px
    font-weight: 500;
    font-size: 0.875em
    line-height: 20px;
    color var(--color_white)
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, var(--color_black_o30) 56.77%);
  }

  &__info {
    display flex
    flex-direction column
    gap 8px
  }

  &__bottom {
    display flex
    flex-wrap wrap
    align-items center
    justify-content space-between
    gap 16px
    font-size 0.875em
    line-height 20px
  }

  &__date {
    color var(--color_gray_dark)
  }

  &__votes {
    display flex
    gap 14px

    & > * {
      display flex
      gap 4px

      svg {
        width 16px
        height 16px
      }

      &.ic-item__likes {
        align-items flex-start
        color var(--color_green_dark)

        svg {
          svg(var(--color_green_dark))
        }
      }
      &.ic-item__dislikes {
        align-items flex-end
        color var(--color_red)

        svg {
          transform rotate(180deg)
          svg(var(--color_red))
        }
      }
    }
  }
}
</style>
